<template>
  <div class="w-full -mt-5" @click="isOpen = true" >
    <label :for="id" class="capitalize ml-1 text-sm font-medium text-gray-700">
      {{ label }}<span class="text-sm text-gray-400 font-italic ml-2">{{ spanLabel }}</span>
    </label>
    <div class="relative">
      <div
        class="px-2 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-black appearance-none z-0 cursor-pointer tracking-wide"
      >
        {{ time }}
      </div>
      <div
        v-if="isOpen"
        class="bg-gray-50 w-[120px] h-[230px] shadow-lg absolute left-2 overflow-hidden"
        @mouseleave="isOpen = false"
      >
        <div class="flex space-x-1 p-1">
          <div class="w-1/2 text-center h-[230px] overflow-y-scroll">
            <p
              v-for="hour in hours"
              :key="hour"
              :class="stateSelected.hour == hour ? 'bg-indigo-500 text-white' : 'hover:bg-indigo-300 hover:text-white'"
              @click="clickSelectTime('hour', hour)"
              class="p-2 cursor-pointer"
            >
              {{ hour }}
            </p>
          </div>
          <div class="w-1/2 text-center h-[230px] overflow-y-scroll">
            <p
              v-for="min in mins"
              :key="min"
              :class="stateSelected.min == min ? 'bg-indigo-500 text-white' : 'hover:bg-indigo-300 hover:text-white'"
              @click="clickSelectTime('min', min)"
              class="p-2 cursor-pointer"
            >
              {{ min }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '00:00'
    },
    id: {
      type: String,
      require: true
    },
    label: {
      type: String,
    },
    spanLabel: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    let isOpen = ref(false)
    let stateSelected = reactive({
      hour: '00',
      min: '00'
    })

    const time = computed(() => `${stateSelected.hour}:${stateSelected.min}`)

    watch(() => props.modelValue, () => {
      initState()
    })
    watch(() => stateSelected.hour, () => {
      emit('update:modelValue', time.value)
    })
    watch(() => stateSelected.min, () => {
      emit('update:modelValue', time.value)
    })

    onBeforeMount(() => {
      initState()
    })

    const initState = () => {
      const [hourSplit, minSplit] = props.modelValue.split(':')
      stateSelected.hour = hourSplit
      stateSelected.min = minSplit
    }

    const clickSelectTime = (type: 'hour' | 'min', value: string) => {
      stateSelected[type] = value
    }

    return {
      stateSelected,
      isOpen,
      time,
      hours,
      mins,
      

      //click
      clickSelectTime
    }
  },
})

const hours = ['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
const mins = ['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
</script>


<style>

</style>